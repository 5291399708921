@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&family=Inconsolata:wght@200;300;400;500;600;700;800;900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Diplomata+SC&family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&family=Inconsolata:wght@200;300;400;500;600;700;800;900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
body{
  margin: 0;
  padding: 0;
  font-family: 'Inconsolata', monospace;
}
*, ::before, ::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
h1, h2, p {
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
  color: black;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
}
:root {
  --yellow: #f5c32c;
  --orange: #fca61f;
  --black: #242d49;
  --gray: #788097;
  --white: #ffffff;
  --background: #fcfcfc;
  --purple: rgb(238 210 255);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --orangeCard: rgba(252, 166, 31, 0.45);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}
@media (min-width: 768px) {
  .container {
    width: 650px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1050px;
  }
}
.container {
  width: 90%;
  margin: auto;
  padding: 40px 0;
}

.App {
  position: relative;
  width: 100%;
  overflow: hidden;
  color: var(--black);
}
.button {
  background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
  box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  padding: 11px 26px 11px 26px;
  position: relative;
  right: -80%;

}
.button:hover {
  background: white;
  cursor: pointer;
  border: 1px solid var(--orange);
  color: var(--orange);
}
@media screen and (max-width: 480px) {
  .App {
    padding: 0.5rem 1rem;
  }
}
nav{
  padding: 0 10px;
  height: 50px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2000;
  box-shadow: 0 0 43px 10px rgb(236, 234, 234) ;
  .logo{
    font-family: 'Diplomata SC', cursive;
    color: transparent;
    -webkit-text-stroke: 1px var(--black);
    letter-spacing: -1px;
    font-variant: small-caps;
  }
  ul{
    display: flex;
    justify-content: flex-end;
    flex: 1;
    width: 100%;
    transition: .5s;
    &.show{left: 0px;}
    @media (max-width:600px) {
      position: absolute;
      z-index: 100000;
      left: 300px;
      top: 50px;
      flex-direction: column;
    }
    li:nth-of-type(2){
      animation-delay: .2s !important;
    }
    li:nth-of-type(3){
      animation-delay: .4s !important;
    }
    li:nth-of-type(4){
      animation-delay: .6s !important;
    }
    li:nth-of-type(5){
      animation-delay: .8s !important;
    }
    li {
      @media (max-width:600px) {
        width: 190px ;
        right: 0;
        display: flex;
        justify-content: center;
        align-self: flex-end;
        border: 1px solid var(--black);
        border-radius: 10px;
        overflow: hidden;
        transform: rotate(-90deg);
        transform-origin: top right;
        &.rotate{
          animation: animation1 1s ease forwards;
        }
        @keyframes animation1 {
          to{transform: rotate(0);}
        }
        &:not(:last-child){
          border-bottom: transparent;
        }
      }
      background-color: white;
      transition: .5s;
      text-align: center;
      width: 80px;
      margin-right: 5px;
      height: 50px;
      line-height: 50px;
      position: relative;
      z-index: 8;
      &:hover::after , &.active::after {width: 100%;}
      &::after{
        cursor: pointer;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        transition: .5s;
        height: 100%;
        background:linear-gradient(to bottom ,var(--black) , white);
      }
      a{
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        z-index: 7;
        font-weight: bold;
        color: transparent;
        -webkit-text-stroke: 2px var(--black) ;
        letter-spacing: 1px;
      }
    }
  }
  .toggle{
    display: none;
    cursor: pointer;
    @media (max-width:600px) {
      display: block;
    }
  }
}

@keyframes topUp {
  to {top: 0;}
}
@keyframes Duration {
  to{top : -85px; left :-99px;}
}
@keyframes waite {
  from{margin-left: -150px;}
  to{margin:3px 0}
}
.home {
  position: relative;
  background-color: var(--background);
  height: calc(100vh - 60px);
  width: 100%;
  top: -80px;
  transition: 1s;
  animation: topUp 1s forwards;
  @media (max-width:600px) {
    width: 90%;
    margin: auto;
  }
  .cont {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    .shap {
      position: absolute;
      .line{
        transition: 1s;
        position: absolute;
        width: 130px;
        height: 332px;
        top: -130px;
        left: -199px;
        border: 3px solid transparent;
        border-left-color: var(--black);
        border-top-color: var(--black);
        animation: Duration 1s forwards;
        &::before{
          content: "";
          position: absolute;
          width: 130px;
          height: 330px;
          left: 267px;
          bottom: -76px;
          border: 3px solid transparent;
          border-right-color: var(--black);
          border-bottom-color: var(--black);
        }
      }
      .left {
        position: absolute;
        width: 16px;
        height: 16px;
        top: -93px;
        left: 35px;
        border-radius: 50%;
        background-color: var(--black);
                border: 2px dotted white;
        &::before {
          content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        top: 0px;
        left: 20px;
        border-radius: 50%;
        background-color: var(--black);
        border: 2px dotted white;
        }
        &::after {
          content: "";
        position: absolute;
        width: 9px;
        height: 9px;
        top: 2px;
        left: 37px;
        border-radius: 50%;
        background-color: var(--black);
        border: 2px dotted white;
        }
      }
      .right{
        position: absolute;
        width: 16px;
        height: 16px;
        bottom: -54px;
        right: 83px;
        border: 2px dotted white;
        border-radius: 50%;
        background-color: var(--black);
        &::before {
          content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        top: 0px;
        left: -16px;
        border-radius: 50%;
        border: 2px dotted white;
        background-color: var(--black);
        }
        &::after {
          content: "";
        position: absolute;
        width: 9px;
        height: 9px;
        top: 2px;
        left: -30px;
        border-radius: 50%;
        background-color: var(--black);
        border: 2px dotted white;
        }
      }
    }
    img {
    // object-fit: cover;
    position: relative;
    min-width: 250px;
    border-radius: 50%;
    top: -13px;
    left: -26px;
    animation: siz 5s forwards;
    @keyframes  siz {
      from{opacity: 0;}
      to{opacity: 1;}
    }
    }
    h2 {
      @media (max-width:600px) {
        white-space: nowrap;
      }
      font-family: "Lobster", cursive;
    font-size: 25px;
    -webkit-text-stroke: 2px #242d499c;
    color: transparent;
    letter-spacing: 2px;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    position: relative;
    top: -54px;
    left: -6px;
      &::after {
        content: attr(data-strok);
        position: absolute;
        left: 0;
        color: var(--black);
        overflow: hidden;
        transition: 0.5s;
        animation: Fill 7s infinite linear;
        white-space: nowrap;
      }
      @keyframes Fill {
        0%,
        100% {
          width: 0;
        }
        50% {
          width: 120%;
        }
      }
    }
    ul {
      position: relative;
      display: flex;
      flex-direction: column;
      top: 0px;
      left: -118px;
      z-index: 9999;
      li  {
        i {
          &:hover{
            font-size: 30px;
          }
          padding: 5px 10px;
          cursor: pointer;
          font-size: 20px;
          transition: 0.3s;
          background: white;
          border: 2px solid var(--black);
          line-height: 40px;
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 3px 0;
          border-radius: 50%;
          animation: waite 1s forwards ;
          
          &.fa-facebook {
            color: #1a478a;
            animation-duration: .2s;
          }
          &.fa-envelope {
            color: #ca473a;
            animation-duration: .4s;

          }
          &.fa-whatsapp {
            color: #4dc258;
            animation-duration: .6s;

          }
          &.fa-github {
            color: #000000;
            animation-duration: .8s;

          }
          &.fa-linkedin {
            color: #007bb6;
            animation-duration: 1s;

          }
        }
      }
    }
  }
  .Download{
    color: white;
    width: 142px;
    position: relative;
    display: block;
    top: 30px;
    left: -48%;
    padding: 5px 10px;
    text-align: center;
    background-color: var(--black);
    letter-spacing: -1px;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
    transition: .4s;
    &:hover{
      opacity: .9;
    }
  }
}
.about {
  overflow: hidden;
  background-color: var(--black);
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 100px;
  .container {
    display: grid;
    grid-template-columns: 1fr 250px;
    gap: 20px;
    align-items: center;
    justify-content: center;
    width: 1000px;
    @media (max-width: 900px) {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }
  h2 {
    font-size: 15px;
    font-weight: normal;
    color: white;
    line-height: 1.5;
    letter-spacing: 0.6px;
    width: 100%;
    -webkit-text-stroke: 1px white;
    text-align: center;
    &::first-line {
      font-family: Tahoma, Geneva, Verdana, sans-serif;
      font-size: 25px;
      margin: 5px 0;
      font-variant: small-caps;
      letter-spacing: 3px;
      color: transparent;
    }
    @media (max-width: 430px) {
      font-size: 14px;
      line-height: 1.2;
    }
  }
  .solar-system {
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 20%;
    .orbit {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--second);
      border-radius: 50%;
    }
    .All {
      width: 165px;
      height: 165px;
      animation: spin 12s linear infinite;
      position: relative;
      border-color: #f1ec50;
      box-shadow: 0 0 10px #f1ec50 inset, 0 0 10px #f1ec50;
    }
    .planet {
      position: absolute;
      top: -5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    .js {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f1ec50;
    }
    .html {
      width: 110px;
      height: 110px;
      animation: spin 8s linear 0s infinite;
      color: #f18282;
      border-color: #f18282;
      box-shadow: 0 0 10px #f18282f0 inset, 0 0 10px #f18282ef;
    }
    .css {
      width: 60px;
      height: 60px;
      color: #2347e9;
      animation: spin 8s linear 0s infinite;
      position: relative;
      border-color: #2347e9;
      box-shadow: 0 0 10px #2347e9ac inset, 0 0 10px #2347e99d;
    }
    .sun {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      width: 30px;
      height: 30px;
      color: rgba(243, 243, 32, 0.758);
      box-shadow: 0 0 10px rgb(243, 243, 14) inset, 0 0 10px rgb(243, 243, 14);
    }
    .react {
      width: 180px;
      height: 180px;
      display: flex;
      color: #00cdf2;
      &::after {
        position: absolute;
        top: -31px;
        left: -26px;
        content: "";
        border: 1px solid;
        width: 230px;
        height: 230px;
        border-radius: 50%;
        box-shadow: 0 0 10px #00cdf2 inset, 0 0 10px #00cdf2;
      }
    }
    @keyframes spin {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }
}
.skills{
  display: flex;
  justify-content: center;
  background-color: var(--background);
  .container{
    padding: 0 ;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    .cover{
      order: 2;
      position: relative;
      width: 500px;
      margin-left: 30px;
      border-radius: 10px;
      overflow: hidden;
      cursor: pointer;
      transition: .5s ;
      &::before{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        clip-path: polygon(0% 0%, 50% 50%, 0% 100%);
        background-color:rgb(237, 177, 64);
        z-index: 4;
      }
      &:hover img{
        transform: scale(1.3) rotate(9deg);
      }
      &:hover::before{
        opacity: 0;
        transition: .5s;
      }
      @media (max-width:900px) {
        display: none;
      }
      img{
        width: 100%;
        transition: .5s;
      }
    }
    .box{
      display: flex;
      flex-direction: column;
      margin: 10px ;
      background-color: var(--white);
      box-shadow: 0 0 100px 10px rgb(234, 232, 232);
      border-radius: 10px 10px 0 0;
      width: 600px;
      @media (max-width:600px) {
        width: 400px;
      }
      
      overflow: hidden;
      h2{
        padding: 10px 10px 20px;
        font-size: 20px;
        color: transparent;
        -webkit-text-stroke: .6px var(--black);
        letter-spacing: -1px;
      }
      .progress{
        position: relative;
        width: 100%;
        height: 5px;
        border-radius: 10px;
        background-color: rgb(223, 223, 223);        
        span{
          position: absolute;
          top: 0;
          left: -1px;
          background-color: var(--black);
          height: 100%;
          transition: 2s;
          border-radius:10px 0 0 10px;
          width: 0%;
          opacity: 0;
          
          &::before{
            content: attr(data-width);
            position: absolute;
            top: -37px;
            font-size: 17px;
            background-color: var(--black);
            padding: 4px;
            border-radius: 4px;
            right: -15px;
            color: transparent;
            -webkit-text-stroke: 0.6px white;
          }
          &::after{
            content: "";
            position: absolute;
            border: 10px solid transparent;
            border-top-color: var(--black);
            top: -12px;
            right: -9px;
          }
        }
      }
      .right{
        position: absolute;
        left: 120px;
        color: #eeebebbe;
        animation: Load 4s linear infinite;
      }
      @keyframes Load {
        0% , 100% {left: 120px;}
        50% {left: 180px;}
      }
    }
  }
}
.work{
  .container{
    position: relative;
    display: grid;
    width: 90%;
    grid-template-columns: repeat(auto-fill , minmax(300px , 1fr));
    @media (max-width:900px) {
      grid-template-columns: repeat(auto-fill , minmax(250px , 1fr));
    }
    gap: 30px;
    .box{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 10px;
      width: 300px;
      height: 250px;
      object-fit: contain;
      overflow: hidden;
      margin: auto;
      animation:  despear 6s forwards ;
      border: 2px solid var(--black);
      &:nth-child(1){
        animation-duration: .5s;
      }
      &:nth-child(2){
        animation-duration: 1s;
      }
      &:nth-child(3){
        animation-duration: 2s;
      }
      &:nth-child(4){
        animation-duration: 3s;
      }
      &:nth-child(5){
        animation-duration: 4s;
      }
      &:nth-child(6){
        animation-duration: 5s;
      }
      @keyframes despear {
        from{opacity: 0; top: -190px;}
        to{opacity: 1; top: 0;}
      }
      &::after{
        content: "";
        position: absolute;
        background-color: rgba(255, 255, 255, 0.085);
        width: 0;
        height: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50% ,-50%);
        transition: .5s;
      }
      &:hover::after{
        width: 400px;
        height: 400px;
      }
      &:hover img{
        transform: scale(1.2) rotate(8deg);
        opacity: .7;
      }
      &:hover h2{
        left: 50%;
      }
      &:hover{
        .content{
          bottom:20px;
        }
      }
      img{
        width: 100%;
        max-width: 100%;
        height: 100%;
        transition: 1s;
        cursor: pointer;
        
      }
      .content{
        position: absolute;
        bottom: -40px;
        transition: .5s;
        text-align: center;
        font-size: 20px;
        display: flex;
        width: 100%;
        height: 30px;
        justify-content: space-between;
        align-items: center;
        gap: 1px;

        .code , .page{
          font-size: 30px;
          width: 50%;
          height: 100%;
          transition: .4s;
          color: #000 ;
          cursor: pointer;
          position: relative;
          z-index: 8;
        }
        .code:hover , .page:hover{
          color: rgb(103, 101, 101);
          font-size: 25px;
          -webkit-text-stroke: 2px black;
          color: transparent;
        }
      }
      h2{
        transition: .5s;
        position: absolute;
        top: 10px;
        color: transparent;
        letter-spacing: -1px;
        -webkit-text-stroke: 1.5px black;
        left: 140%;
        transform: translateX(-50%);
        font-weight: normal;
        white-space: nowrap;
      }
    }
  }
}
.contact{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  margin: auto;
  @media (max-width:600px) {
    width: 400px;
    text-align: center;
  }
  h2{
    font-size: 40px;
    color: transparent;
    letter-spacing: -1px;
    -webkit-text-stroke: .9px black;
    font-style: italic;
    text-transform: capitalize;
    span{
      -webkit-text-stroke: .9px orange;
    }
  }
  form{
    margin: 20px;
  .box{
    position: relative;
      margin: 20px 0;
      width: 100%;
      @media (max-width:600px) {
        width: 400px;
      }
      label{
        position: absolute;
        left: 24px;
        top: 7px;
        color: transparent;
        letter-spacing: 1px;
      }
      .Name , .Email , textarea {
        @media (max-width:600px) {
          width: 400px;
        }
        margin: auto;
        width: 500px;
        position: relative;
        display: block;
        height: 40px;
        margin-bottom: 40px;
        border-radius: 10px;
        outline: none;
        border: 1px solid #777;
        padding-left: 20px;
      }
      .Message {
        min-height: 150px;
        max-height: 150px;
        max-width: 500px;
        min-width: 500px;
        &:focus::placeholder{
          opacity: 0;
        }
        @media (max-width:600px) {
          max-width: 400px;
          min-width: 400px;
        }
        
      }
      input:focus{
        border-color: orange;
      }
      input:focus::placeholder{opacity: 0;}
      input:focus + label , textarea:focus + label {
        left: 11px;
        top: -30px;
        font-size: 19px;
        color: orange;
        transition: .5s  ease-out;
      }
    }
    .Submit{
      transition: .5s;
      @media (max-width:600px) {
        right:-36%;
      }
    }
  }
}